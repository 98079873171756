import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["there"]

  connect() {
    this.thereTarget.innerHTML = "Yo, Stimulus has loaded."
  }

  doSomething() {
    this.thereTarget.innerHTML = "Stimulus has changed the text!"
  }
}
